import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { useConversations } from "~/hooks/useConversation";
import { useCurrentUser } from "~/hooks/useCurrentUser";
import { useLocalStorage } from "~/hooks/useLocalStorage";
import { useProjects } from "~/hooks/useProjects";

type ProjectUrlParams = {
  conversationId?: string;
  projectName?: string;
};

export enum PickerView {
  Projects,
  Conversations,
}

const useProjectPicker = () => {
  const { conversationId, projectName } = useParams<ProjectUrlParams>();
  const { organizationId: currentOrgId } = useCurrentUser();
  const { data: projects } = useProjects();
  const [shouldPoll, setShouldPoll] = useState<boolean>(false);
  const [view, setView] = useState<PickerView>(
    projectName ? PickerView.Conversations : PickerView.Projects,
  );
  /** name of the project whose conversations are rendered in the picker.
   * stored as a name and not an id so we can easily sync with the url.  may be
   * different from the name of the project currently in the url, due to the
   * user browsing the contents of different projects */
  const [selectedProjectName, setSelectedProjectName] = useState<
    string | undefined
  >(projectName);
  const selectedProject = projects?.find((p) => p.name === selectedProjectName);
  const { data: conversations } = useConversations(
    selectedProject?.id,
    shouldPoll,
  );
  const selectedConversation = conversations?.find(
    (c) => c.id === conversationId,
  );
  const [collapsed, setCollapsed] =
    useLocalStorage<boolean>("picker:collapsed");
  const [showAddProjectDialog, setShowAddProjectDialog] =
    useState<boolean>(false);
  const [showAddConversationDialog, setShowAddConversationDialog] =
    useState<boolean>(false);
  const sortedConversations = conversations?.toSorted(
    (c1, c2) =>
      new Date(c1.created_at).getTime() - new Date(c2.created_at).getTime(),
  );
  const onSelectProject = useCallback(
    (projectId: string) => {
      setSelectedProjectName(projects.find((p) => p.id === projectId)?.name);
      setView(PickerView.Conversations);
    },
    [projects],
  );
  const onBack = useCallback(() => {
    setView(PickerView.Projects);
    setSelectedProjectName(projectName);
  }, [projectName]);
  const toggleCollapse = useCallback(() => {
    setCollapsed(!collapsed);
  }, [collapsed, setCollapsed]);
  const onAddProject = useCallback(() => {
    setShowAddProjectDialog(true);
  }, []);
  const onAddConversation = useCallback(() => {
    setShowAddConversationDialog(true);
  }, []);
  const onCloseDialog = useCallback(() => {
    setShowAddConversationDialog(false);
    setShowAddProjectDialog(false);
  }, []);

  useEffect(() => {
    setShouldPoll(
      !!conversations?.some(
        (c) =>
          c.status === "PENDING" || c.status === "RUNNING" || c.threat_feed,
      ),
    );
  }, [conversations]);

  useEffect(() => {
    setSelectedProjectName(projectName);
  }, [projectName, projects]);

  useEffect(() => {
    /* reset picker upon org change */
    return function cleanup() {
      setSelectedProjectName(undefined);
      setView(PickerView.Projects);
    };
  }, [currentOrgId]);

  return {
    collapsed,
    conversations,
    onAddConversation,
    onAddProject,
    onBack,
    onCloseDialog,
    onSelectProject,
    projects,
    selectedConversation,
    selectedProject,
    selectedView: view,
    showAddConversationDialog,
    showAddProjectDialog,
    toggleCollapse,
    visibleConversations: sortedConversations,
  };
};

export { useProjectPicker };
