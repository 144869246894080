import { styled } from "@linaria/react";
import { type StyledComponent, type FunctionComponent } from "react";

import { type ProjectDTO } from "~/dto";
import { text } from "~/styles/typography";

import AddConversationDialog from "../AddEditConversationDialog";
import Section, { Heading } from "../Section";
import Icon from "../library/Icon";
import IconButton, { ButtonKind, ButtonSize } from "../library/IconButton";

import AddProjectDialog from "./AddEditProjectDialog";
import PickerList from "./PickerList";
import { PickerView, useProjectPicker } from "./useProjectPicker";

const CollapseButton = styled(IconButton)`
  position: absolute;
  bottom: 72px;
  right: calc(-1 * var(--spacing-md));
  min-width: var(--min-width-click-target);
  min-height: var(--min-width-click-target);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: var(--spacing-md);
  z-index: var(--z-index-conversation-list-collapser);
  background-color: none;

  &:hover [data-icon] {
    background-color: var(--background-color-button-borderless-hover);
  }

  [data-icon] {
    font-size: 18px;
    padding: var(--gutter-25);
    border: var(--border-button-secondary);
    color: var(--color-button-borderless);
    background-color: var(--background-color-button-secondary);
  }
`;

const StyledPickerList = styled(PickerList)`
  align-items: stretch;

  li {
    min-width: 40px;
  }
`;

const ProjectPickerContainer = styled(Section)`
  border-right: 1px solid var(--border-color-primary);
  padding: var(--spacing-lg);
  width: 240px;
  min-width: 240px;
  position: relative;
  contain: strict;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-3xl);

  &[data-collapsed="true"] {
    padding: 0;
    width: 56px; /* ButtonSize.sm + 2 characters + border + (thin) scrollbar */
    min-width: 56px;
    gap: var(--spacing-xl);

    ${StyledPickerList} {
      align-items: center;
    }
  }
`;

type PickerHeaderProps = {
  collapsed: boolean;
  id: string;
  onAddConversation: () => void;
  onAddProject: () => void;
  onBack: () => void;
  selectedProject?: ProjectDTO;
  selectedView: PickerView;
};

const PickerHeaderContainer = styled.div`
  display: grid;
  grid-template-areas:
    "back-button title   add-button"
    ".           content .";
  grid-template-columns: max-content 1fr max-content;
  align-items: center;
  column-gap: var(--spacing-md);
  ${text.md.medium};

  [slot="title"] {
    ${text.md.medium};

    [data-icon] {
      margin: var(--spacing-md) 0;
      width: 20px;
      height: 20px;
    }
  }

  [slot="back-button"] {
    border: 1px solid transparent; /* balance this button (tertiary, 0px border) with the add button (primary, 1px) */
    grid-area: back-button;
    align-self: start;

    &[disabled] {
      /* visually hide button but leave it for ATs */
      opacity: 0;
    }
  }

  [slot="add-button"] {
    grid-area: add-button;
    align-self: start;
  }

  [slot="content"] {
    grid-area: content;
    ${text.sm.medium};
  }

  &[data-collapsed="true"] {
    display: flex;
    flex-direction: column;
    align-items: center;

    [slot="content"] {
      display: none;
    }

    [slot="back-button"][disabled] {
      /* visually hide button but leave it for ATs */
      position: absolute;
      width: 1px;
      height: 1px;
      overflow: hidden;
      clip: rect(0 0 0 0);
      clip-path: inset(100%);
    }

    [slot="back-button"],
    [slot="add-button"] {
      align-self: center;
    }
  }
`;

const PickerHeader: FunctionComponent<PickerHeaderProps> = (props) => {
  const {
    collapsed,
    id,
    onAddConversation,
    onAddProject,
    onBack,
    selectedProject,
    selectedView,
  } = props;

  return (
    <PickerHeaderContainer data-collapsed={collapsed}>
      <IconButton
        aria-label="back to projects list"
        data-kind={ButtonKind.Tertiary}
        data-size={ButtonSize.sm}
        disabled={selectedView === PickerView.Projects}
        icon="arrow-left"
        onPress={onBack}
        slot="back-button"
      />
      <Heading id={id} slot="title">
        {collapsed && (
          <Icon
            family="untitled"
            name={
              selectedView === PickerView.Projects
                ? "folder"
                : "message-chat-square"
            }
          />
        )}
        <span data-at-only={collapsed ? "true" : undefined}>
          {selectedView === PickerView.Projects ? "Projects" : "Conversations"}
        </span>
      </Heading>
      <IconButton
        aria-label={
          selectedView === PickerView.Projects
            ? "Add Project"
            : "Add Conversation"
        }
        data-kind={ButtonKind.Primary}
        data-size={ButtonSize.sm}
        icon="plus"
        onPress={
          selectedView === PickerView.Projects
            ? onAddProject
            : onAddConversation
        }
        slot="add-button"
      />
      <div slot="content">
        {selectedView === PickerView.Conversations && selectedProject
          ? `in ${selectedProject?.name}`
          : null}
      </div>
    </PickerHeaderContainer>
  );
};

const ProjectPicker: FunctionComponent<StyledComponent> = (props) => {
  const {
    collapsed,
    onAddConversation,
    onAddProject,
    onBack,
    onCloseDialog,
    onSelectProject,
    projects,
    selectedConversation,
    selectedProject,
    selectedView,
    showAddConversationDialog,
    showAddProjectDialog,
    toggleCollapse,
    visibleConversations,
  } = useProjectPicker();
  const labelId = "picker-list-label";

  return (
    <ProjectPickerContainer {...props} data-collapsed={collapsed}>
      <PickerHeader
        collapsed={collapsed}
        id={labelId}
        onAddConversation={onAddConversation}
        onAddProject={onAddProject}
        onBack={onBack}
        selectedProject={selectedProject}
        selectedView={selectedView}
      />
      <StyledPickerList
        collapsed={collapsed}
        conversations={visibleConversations}
        labelId={labelId}
        onSelectProject={onSelectProject}
        projects={projects}
        selectedConversation={selectedConversation}
        selectedProject={selectedProject}
        selectedView={selectedView}
      />
      <CollapseButton
        aria-expanded={!collapsed}
        aria-label="expand/collapse conversation list"
        data-kind={ButtonKind.Secondary}
        data-size={ButtonSize.md}
        icon={collapsed ? "arrow-right" : "arrow-left"}
        onPress={toggleCollapse}
        type="button"
      />

      <AddProjectDialog isOpen={showAddProjectDialog} onClose={onCloseDialog} />
      {selectedProject && (
        <AddConversationDialog
          isOpen={showAddConversationDialog}
          onClose={onCloseDialog}
          projectId={selectedProject.id}
        />
      )}
    </ProjectPickerContainer>
  );
};

export default ProjectPicker;
