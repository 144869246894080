import {
  type ConversationDTO,
  ConversationListWithEnvelope,
} from "~/dto/conversation";

import { useConversationMutations } from "./useConversation";
import {
  useRemoteCollection,
  useRemoteObjectFromCollection,
} from "./useRemoteData";
import {
  type GetFetcherFactory,
  defaultGetFetcherFactory,
} from "./useRemoteData/utils";

const scaledConversationsGetFetcherFactory: GetFetcherFactory<
  ConversationDTO[]
> = (...args) => {
  const fetcher = defaultGetFetcherFactory(...args);

  return async (request, init) => {
    try {
      return await fetcher(request, init);
    } catch {
      /* if an error is thrown, the default fetcher has already logged it.
      we'll ignore it here and return an empty array */
      return [];
    }
  };
};

export function useScaledConversations() {
  return useRemoteCollection<ConversationDTO>("/api/v2/conversations/scaled", {
    getFetcher: scaledConversationsGetFetcherFactory,
    schemaValidator: ConversationListWithEnvelope,
  });
}

export function useScaledConversation(conversationId: string) {
  const remoteObject = useRemoteObjectFromCollection<ConversationDTO>(
    "/api/v2/conversations/scaled",
    conversationId,
    {
      schemaValidator: ConversationListWithEnvelope,
    },
  );

  return useConversationMutations(conversationId, remoteObject);
}
